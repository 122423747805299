<template>
  <div></div>
</template>

<script>
import config from '@/config'
export default {
  name: "RedirectToApp",
  beforeMount() {
    let redirectTo = '/'
    const devices = [
      {
        name: 'ios',
        type: 'iPhone|iPod',
        url: config.iosAppUrl
      },
      {
        name: 'android',
        type: 'android',
        url: config.androidAppUrl
      },
    ]

    devices.forEach(({ type, url }) => {
      let typeRegx = new RegExp(type, 'i')
      if((navigator.userAgent.match(typeRegx))) {
        redirectTo = url;
      }
    })

    window.location = redirectTo
  }
};
</script>

<style scoped>

</style>